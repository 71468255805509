import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import PageContext from "../context/pageContext";

interface PostProps {
  data: {
    mdx: {
      fields: {
        slug: string;
      };
      body: string;
      frontmatter: {
        title: string;
        date: Date;
      };
    };
  };
}

const Post: React.FC<PostProps> = ({ data }) => {
  const post = data.mdx;
  return (
    <PageContext.Consumer>
      {({ toggleMenu }) => (
        <Layout>
          <div className="post">
            <div className="post__breadcrumb" aria-hidden="true">
              <Link to="/blog" onClick={() => toggleMenu("closed")}>
                Blog
              </Link>{" "}
              / {post.frontmatter.title}
            </div>
            <h3 className="post__title--post">{post.frontmatter.title}</h3>
            <h4 className="post__date">{post.frontmatter.date}</h4>
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
        </Layout>
      )}
    </PageContext.Consumer>
  );
};

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY", locale: "en-US")
      }
    }
  }
`;

export default Post;
